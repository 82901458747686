import React from 'react';

import { CTA_GET_MY_FORMULA } from 'consts/copy';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { SEO, SEO_RETAIL_OFFER_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';
import { ConvertSnippet } from 'components/global/ConvertSnippet';
import { RETAIL_OFFER_BANNER_CONTENT } from 'components/global/Nav/components';
import {
  BenefitsBar,
  RETAIL_OFFER_RESKIN_BENEFITS_BAR_CONTENT,
} from 'components/modules/BenefitsBar';
import {
  BeforeAfterPop,
  POP_REDESIGN_BEFORE_AFTER_CONTENT,
} from 'components/modules/pop-redesign/BeforeAfterPop';
import {
  HeroPop,
  RETAIL_OFFER_HERO_CONTENT,
} from 'components/modules/pop-redesign/HeroPop';
import {
  IngredientsPop,
  POP_REDESIGN_INGREDIENTS_CONTENT,
} from 'components/modules/pop-redesign/IngredientsPop';
import {
  ProductsPop,
  POP_REDESIGN_PRODUCTS_CONTENT,
} from 'components/modules/pop-redesign/ProductsPop';
import {
  StepsPop,
  RETAIL_OFFER_STEPS_CONTENT,
} from 'components/modules/pop-redesign/StepsPop';
import { LOCAL_COLORS } from 'styles/constants';
import {
  Footer,
  FOOTER_RETAIL_LP_OFFER_CONTENT,
} from 'components/global/Footer';
import { Faq, FAQ_RETAIL_OFFER_RESKIN_CONTENT } from 'components/modules/Faq';
import { PARTNERS_CONTENT, Partners } from 'components/modules/Partners';

const RetailOfferLandingPage = () => {
  return (
    <LandingPageWrapper>
      <SEO {...SEO_RETAIL_OFFER_CONTENT} />
      <ConvertSnippet />
      <Nav
        ctaTextOverride={CTA_GET_MY_FORMULA}
        bannerProps={[RETAIL_OFFER_BANNER_CONTENT]}
      />
      <HeroPop {...RETAIL_OFFER_HERO_CONTENT} />
      <BenefitsBar {...RETAIL_OFFER_RESKIN_BENEFITS_BAR_CONTENT} />
      <StepsPop {...RETAIL_OFFER_STEPS_CONTENT} />
      <BeforeAfterPop {...POP_REDESIGN_BEFORE_AFTER_CONTENT} />
      <IngredientsPop
        {...POP_REDESIGN_INGREDIENTS_CONTENT}
        backgroundColor={LOCAL_COLORS.surfaceBrandLightest}
      />
      <ProductsPop {...POP_REDESIGN_PRODUCTS_CONTENT} />
      <Faq {...FAQ_RETAIL_OFFER_RESKIN_CONTENT} />
      <Partners {...PARTNERS_CONTENT} />
      <Footer {...FOOTER_RETAIL_LP_OFFER_CONTENT} />
    </LandingPageWrapper>
  );
};

export default RetailOfferLandingPage;
